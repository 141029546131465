/* eslint-disable */
import { defineComponent } from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import componentI18n from '@/i18n/componentI18n';
const minutesInAnHour = 60;
export default defineComponent({
    name: 'DateTimePicker',
    ...componentI18n('views.retro.new.time'),
    computed: {
        ...mapState('newRetro', ['duration', 'retroDate', 'retroTime']),
        ...mapGetters('newRetro', ['isBehindSchedule', 'invalidTime', 'invalidDuration']),
        decomposedDuration() {
            return {
                hours: Math.floor(this.duration / minutesInAnHour),
                minutes: this.duration % minutesInAnHour
            };
        },
        timeBreakpoints() {
            return {
                tooShort: 30,
                short: 60,
                long: 90,
                tooLong: 150
            };
        },
        includeMinutes() {
            return this.decomposedDuration.minutes === 0 ? 'no_minutes' : 'with_minutes';
        },
        retroTimeError() {
            if (this.invalidTime) {
                return this.$t('empty_time');
            }
            else if (this.isBehindSchedule) {
                return this.$t('past_retro');
            }
            else {
                return null;
            }
        }
    },
    methods: {
        ...mapMutations('newRetro', ['updateDuration', 'updateDate', 'updateTime']),
        isPastDate(date) {
            const today = moment().startOf('day');
            return moment(date).isBefore(today);
        }
    }
});
