import { defineComponent } from 'vue';
import componentI18n from '@/i18n/componentI18n';
import NamedCard from '@/components/new/NamedCard.vue';
import MaturitySlider from '@/components/MaturitySlider.vue';
export default defineComponent({
    name: 'ActivityHelper',
    components: {
        NamedCard,
        MaturitySlider
    },
    ...componentI18n('views.retro.new.activity'),
    props: {
        maturity: { type: Number, required: true },
        projectStage: { type: String, default: null }
    },
    computed: {
        liveProjectStage: {
            get() {
                return this.projectStage;
            },
            set(newValue) {
                this.$emit('update:projectStage', newValue);
            }
        },
        liveMaturity: {
            get() {
                return this.maturity;
            },
            set(newValue) {
                this.$emit('update:maturity', newValue);
            }
        },
        projectStageOptions() {
            return [
                { value: 'starting', label: this.$t('project_stage.starting.label') },
                { value: 'standard', label: this.$t('project_stage.standard.label') },
                { value: 'deadline', label: this.$t('project_stage.deadline.label') }
            ];
        }
    }
});
