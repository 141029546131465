import { defineComponent } from 'vue';
import componentI18n from '@/i18n/componentI18n';
import { createNamespacedHelpers } from 'vuex';
import { TEAM_CONSTANTS } from '@/constants/index';
const { mapGetters, mapMutations, mapState } = createNamespacedHelpers('newRetro');
export default defineComponent({
    name: 'NameCard',
    ...componentI18n('views.retro.new.advanced.retro_name'),
    computed: {
        ...mapState(['retroName']),
        ...mapGetters(['isRetroNameEmpty', 'isRetroNameTooLong']),
        retroNameError() {
            if (this.isRetroNameTooLong) {
                return this.$t('errors.name.too_long');
            }
            else if (this.isRetroNameEmpty) {
                return this.$t('errors.name.empty');
            }
            else {
                return null;
            }
        },
        getMaxLength() {
            return TEAM_CONSTANTS.MAX_NAME_LENGTH;
        }
    },
    methods: {
        ...mapMutations(['updateRetroName'])
    }
});
