<template>
  <named-card
    tag="li"
    class="row-span-2"
    :class="{
      'border-red-500 dark:border-red-500': invalid || invalidPostingTime || invalidWritingTime
    }"
    :name="$t('title')"
  >
    <div class="flex flex-col items-center font-bold leading-none text-gray-600 dark:text-gray-300">
      <div>{{ $t('total') }}</div>
      <div class="text-4xl">{{ duration }}</div>
      <div>{{ $t('minutes') }}</div>
    </div>

    <paragraph small class="my-3">
      {{ $t('description') }}
    </paragraph>

    <ol class="mb-2 space-y-2">
      <li class="flex items-center justify-between">
        <div class="text-gray-900 dark:text-gray-100">{{ $t('phases.write') }}</div>
        <div class="flex items-end space-x-2">
          <number-field
            integer
            :model-value="retroWritingDuration"
            :label="$t('writing_duration_sr')"
            hide-label
            size="lg"
            :min="0"
            :max="duration - retroPostingDuration"
            :error-border="invalid || invalidWritingTime"
            class="w-15"
            @update:model-value="updateRetroWritingDuration"
          />
          <paragraph small>{{ $t('minutes') }}</paragraph>
        </div>
      </li>

      <li class="flex items-center justify-between">
        <div>
          <div class="text-gray-900 dark:text-gray-100">{{ $t('phases.share') }}</div>
          <paragraph small>
            {{
              postingTurnDuration >= 2
                ? $t('x_minutes_each', { x: formattedPostingTurnDuration })
                : $t('turn_too_short')
            }}
          </paragraph>
        </div>
        <div class="flex items-end space-x-2">
          <number-field
            integer
            :model-value="retroPostingDuration"
            :label="$t('posting_duration_sr')"
            hide-label
            size="lg"
            :min="0"
            :max="duration - retroWritingDuration"
            :error-border="invalid || invalidPostingTime"
            class="w-15"
            @update:model-value="updateRetroPostingDuration"
          />
          <paragraph small>{{ $t('minutes') }}</paragraph>
        </div>
      </li>

      <li class="flex items-center justify-between">
        <div class="text-gray-900 dark:text-gray-100">{{ $t('phases.rest') }}</div>
        <div class="flex items-end space-x-2">
          <number-field
            integer
            :model-value="remainingTime"
            :label="$t('rest_duration_sr')"
            hide-label
            size="lg"
            :min="0"
            :max="duration"
            disabled
            :error-border="invalid"
            class="w-15"
          />
          <paragraph small>{{ $t('minutes') }}</paragraph>
        </div>
      </li>
    </ol>

    <div class="flex flex-col-reverse">
      <text-button :disabled="!changed" @click="reset">
        {{ $t('reset') }}
      </text-button>
      <div class="flex-1">
        <paragraph v-if="errorMessage" small class="text-red-500 dark:text-red-500">
          {{ errorMessage }}
        </paragraph>
      </div>
    </div>
  </named-card>
</template>

<script>
import componentI18n from '@/i18n/componentI18n';
import { mapGetters, mapMutations, mapState } from 'vuex';
import NamedCard from '@/components/new/NamedCard';

export default {
  name: 'TimingCard',
  components: {
    NamedCard
  },
  data() {
    return {
      originalWritingDuration: null,
      originalPostingDuration: null
    };
  },
  computed: {
    ...mapGetters('newRetro', ['participantSlots']),
    ...mapState('newRetro', ['duration', 'retroPostingDuration', 'retroWritingDuration']),
    remainingTime() {
      return this.duration - this.retroPostingDuration - this.retroWritingDuration;
    },
    postingTurnDuration() {
      return this.retroPostingDuration / this.participantSlots;
    },
    formattedPostingTurnDuration() {
      let rounded = Math.round(this.postingTurnDuration);
      if (rounded === this.postingTurnDuration) {
        return rounded;
      } else {
        return `~${rounded}`;
      }
    },
    invalid() {
      return this.remainingTime <= 0;
    },
    invalidWritingTime() {
      return this.retroWritingDuration === 0;
    },
    invalidPostingTime() {
      return this.postingTurnDuration === 0;
    },
    changed() {
      return (
        this.retroWritingDuration !== this.originalWritingDuration ||
        this.retroPostingDuration !== this.originalPostingDuration
      );
    },
    errorMessage() {
      let message = '';
      if (this.invalid) {
        message = this.$t('values_too_long');
      } else if (this.invalidWritingTime) {
        message = this.$t('writing_duration_required');
      } else if (this.invalidPostingTime) {
        message = this.$t('posting_duration_required');
      }

      return message;
    }
  },
  mounted() {
    this.originalWritingDuration = this.retroWritingDuration;
    this.originalPostingDuration = this.retroPostingDuration;
  },
  ...componentI18n('views.retro.new.advanced.timing'),
  methods: {
    ...mapMutations('newRetro', ['updateRetroPostingDuration', 'updateRetroWritingDuration']),
    reset() {
      this.updateRetroWritingDuration(this.originalWritingDuration);
      this.updateRetroPostingDuration(this.originalPostingDuration);
    }
  }
};
</script>
