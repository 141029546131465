import { defineComponent } from 'vue';
import componentI18n from '@/i18n/componentI18n';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapState } = createNamespacedHelpers('newRetro');
export default defineComponent({
    name: 'CallLinkCard',
    ...componentI18n('views.retro.new.advanced.call_link'),
    computed: {
        ...mapState(['callLink']),
        ...mapGetters(['isCallLinkTooLong', 'hasCallLinkWhiteSpaces', 'isCallLinkInvalidUrl']),
        callLinkError() {
            if (this.isCallLinkTooLong) {
                return this.$t('too_long');
            }
            else if (this.hasCallLinkWhiteSpaces) {
                return this.$t('white_spaces');
            }
            else if (this.isCallLinkInvalidUrl) {
                return this.$t('invalid_url');
            }
            else {
                return null;
            }
        }
    },
    methods: {
        ...mapMutations(['updateCallLink'])
    }
});
