<template>
  <component
    :is="tag"
    class="p-4 border border-gray-300 rounded-lg dark:border-gray-900 dark:bg-gray-700"
  >
    <div class="mb-5">
      <span
        class="px-3 py-1 mb-5 text-sm rounded-full text-primary-900 bg-primary-100 dark:bg-gray-600 dark:text-gray-300"
      >
        {{ name }}
      </span>
    </div>
    <slot />
  </component>
</template>

<script>
export default {
  name: 'NamedCard',
  props: {
    tag: { type: String, default: 'div' },
    name: { type: String, required: true }
  }
};
</script>
